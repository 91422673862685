import { styles, bgHeader, heroImg } from "./HomeAssets";

const HomeHeader = () => {
  return (
    <div
      className={styles.header}
      style={{ backgroundImage: `url('${bgHeader}')` }}
    >
      <div className="container">
        <div className="row mt-1 justify-content-center">
          <div className="col-sm-7 order-sm-0 order-1 d-flex justify-content-center align-items-center">
            <div>
              <h1 style={{ textTransform: "capitalize" }}>
                Selamat datang di warung makan yang enak tapi gak bikin kantong
                jebol!
              </h1>
              <p>
                Warung Nasi Zein Pemalang merupakan pelopor Ayam Gepuk di Jl KH
                Agus Salim, Kota Tangerang — Kamu lapar? mau makan hemat? yuk
                mampir ke Warung Nasi Zein Pemalang, kami punya promo spesial{" "}
                <strong>Ayam Gepuk gratis buat kamu!</strong>
              </p>

              <div className="mt-5">
                <a href="#contact" className={`btn ${styles.btn_orange} me-2`}>
                  Hubungi Kami
                </a>
                <a href="https://app.zeinpemalang.com/coupon/collect" className={`btn ${styles.btn_orange}`}>
                  Klaim Kupon
                </a>
              </div>
            </div>
          </div>
          <div className="col order-sm-1 order-0 mb-sm-0 mb-5 d-flex justify-content-center align-items-center">
            <img className={styles.hero_image} src={heroImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
